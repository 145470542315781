import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import AboutPagesContent from "../containers/AboutPagesContent"
import BreadCrumbsAbout from "../containers/BreadcrumbsAbout"
import ChangeYourLife from "../containers/ChangeYourLife"
import Layout from "../containers/Layout"
import PageTitle from "../containers/PageTitle"
import SEO from "../components/SEO"
import { ContentWrapper } from "../containers/elegant.style"

const aboutPagesTemplate = ({ data }) => {
  const {
    content,
    seoDetails,
    title,
    subtitle,
    slug,
    menuTitle,
  } = data.contentfulAboutPages

  return (
    <>
      <SEO
        title={seoDetails.metaTitle}
        shortDesc={seoDetails.metaShortDescription}
        description={seoDetails.metaDescription}
      />
      <Layout>
        <ContentWrapper>
          <BreadCrumbsAbout currentPage={menuTitle} />
          <PageTitle title={title} subtitle={subtitle} />
          <AboutPagesContent content={content} slug={slug} />
          <ChangeYourLife
            whiteBG
            title="Ready to change your life?"
            text="Begin your journey with us today. With Elegant Beauty clinics across the Bournemouth, Poole and Southampton area, we invite you to visit us for a free consultation at your convenience."
          />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default aboutPagesTemplate

export const aboutPagesQuery = graphql`
  query aboutPageBySlug($slug: String!) {
    contentfulAboutPages(slug: { eq: $slug }) {
      title
      subtitle
      menuTitle
      slug
      content {
        json
      }
      seoDetails {
        metaTitle
        metaShortDescription
        metaDescription
      }
    }
  }
`
